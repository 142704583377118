import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PercentIcon from "@mui/icons-material/Percent";
import PromoCard from "../../components/promoCard/PromoCard";
import { Link } from "react-router-dom";
import HomepagePopup from "../../components/homepagePopup/HomepagePopup";


const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        {/* <h1 className="homeTitle">PIZZERIA OMIKA</h1> */}
        <div className="homeWrapper">
          <div className="left">
            <h1>Jak złożyć zamówienie?</h1>
            <p>Złóż zamówienie online i czekaj na naszego dostawcę</p>
            <div className="homeCards">
              <div className="homeCard">
                <TouchAppIcon className="icon" />
                <h1>Dodaj do koszyka</h1>
                <p>
                  Wybierz interesujące Cię produkty z naszego menu i dodaj do
                  koszyka
                </p>
              </div>
              <div className="homeCard">
                <PriceCheckIcon className="icon" />
                <h1>Uzupełnij dane dostawy</h1>
                <p>
                  Wskaż adres pod który mamy dostarczyć zamówienie i zapłać
                  online lub wybierz płatność przy odbiorze
                </p>
              </div>
              <div className="homeCard">
                <DirectionsCarIcon className="icon" />
                <h1>Czekaj na realizację</h1>
                <p>
                  My zajmiemy się resztą byś jak najszybciej mógł otrzymać swoje
                  zamówienie
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <Link to="/menu" style={{ textDecoration: "none" }}>
              <button className="menuButton">SPRAWDŹ MENU</button>
            </Link>


 
                  
   {/* !! PROMOCJA URODZINY */}
            <div className="discount-banner">
              <div className="line"></div>
              <PercentIcon className="icon" />
              <div className="text">JESIENNA PROMOCJA</div>
              <PercentIcon className="icon" />
              <div className="line"></div>
            </div>
            <div className="promo-banner">
              <h4>
                OFERTA PROMOCYJNA DOSTĘPNA TYLKO W DNIACH 14.10-31.10.2024
              </h4>
            </div>
               
       <div className="promoCards">
              <PromoCard
                title="2xPIZZA O ROZMIARZE 30CM"
                description="Jesienna promocja w Pizzerii Omika!"
                image="https://images2.imgbox.com/5b/29/vou77RiJ_o.jpg"
                discount="JEDYNIE ZA 59,95ZŁ"
                name="DWIE ŚREDNIE"
                price={59.95}
              />
              <PromoCard
                title="2xBURGER+2xFRYTKI"
                description="Jesienna promocja w Pizzerii Omika!"
                image="https://images2.imgbox.com/80/83/6YrjzMFu_o.jpg"
                discount="JEDYNIE ZA 59,95ZŁ"
                name="2xBURGER+2xFRYTKI"
                price={59.95}
              />
               <PromoCard
                title="2xZAPIEKANKA+2xFRYTKI"
                description="Jesienna promocja w Pizzerii Omika!"
                image="https://images2.imgbox.com/d9/e2/8bBigQeo_o.jpg"
                discount="JEDYNIE ZA 49,95ZŁ"
                name="2xZAPIEKANKA+2xFRYTKI"
                price={49.95}
              />
            </div>
        {/* !! PROMOCJA URODZINY */}                


            <div className="discount-banner">
              <div className="line"></div>
              <PercentIcon className="icon" />
              <div className="text">STREFA PROMOCJI</div>
              <PercentIcon className="icon" />
              <div className="line"></div>
            </div>
 <div className="promo-banner">
              <h4>OFERTA PROMOCYJNA DOSTĘPNA TYLKO PRZY ZAMÓWIENIACH SKŁADANYCH ZA POŚREDNICTWEM STRONY INTERNETOWEJ</h4>
            </div>
                      <div className="promoCards">
              <PromoCard
                title="DLA NIEJ"
                description="Zanurz się w rozkosznej uczcie smaków! Nasza wyjątkowa zapiekanka, chrupiące frytki i świeża sałatka to idealne trio, które zachwyci Twoje podniebienie. Teraz dostępne razem za jedynie 44,95 zł!"
                image="https://images2.imgbox.com/c7/a1/RTlkqUqN_o.jpg"
                discount="JEDYNIE ZA 44,95ZŁ"
                name="DLA NIEJ: Zapieksa + Frytki + Sałatka"
                price={44.95}
              />
              <PromoCard
                title="DLA NIEGO"
                description="Moc smaku zamknięta w jednym zestawie! Nasz soczysty burger, złociste frytki i kolorowa sałatka to połączenie, które zaspokoi nawet największe głodne brzuchy. Zamów teraz za 49,95 zł!"
                image="https://images2.imgbox.com/26/f1/HBf6e5SH_o.jpg"
                discount="JEDYNIE ZA 49,95ZŁ"
                name="DLA NIEGO: Burger + Frytki + Sałatka"
                price={49.95}
              />
              <PromoCard
                title="DLA DZIECKA"
                description="Zabierz swoje maluchy w podróż smaków! Zestaw frytek i 5 nuggetsów to pyszny i zabawny sposób na zaspokojenie ich apetytu. Teraz dostępne za jedynie 17,95zł!"
                image="https://images2.imgbox.com/23/02/K5axKgnD_o.jpg"
                discount="JEDYNIE ZA 17,95ZŁ"
                name="DLA DZIECKA: Frytki + 5x Nuggetsy"
                price={17.95}
              />
              <PromoCard
                title="NUGGETSOWA UCZTA"
                description="Zapraszamy do krainy kawałków soczystego mięsa! 20 nuggetsów, 3 porcje chrupiących frytek - wszystko to teraz za jedynie 59,95 zł!"
                image="https://images2.imgbox.com/80/e0/s4YFH5EH_o.jpg"
                discount="OSZCZĘDZASZ 10ZŁ"
                name="3x Frytki + 20x Nuggetsy"
                price={59.95}
              />
              <PromoCard
                title="TRZY ŚREDNIE, TRZY RAZY RADOŚĆ"
                description="Zestaw trzech średnich pizz to trzykrotna dawka smaku i radości! Zamów teraz za 104,95 zł i ciesz się różnorodnością aromatów, które skradną Twoje podniebienie."
                image="https://images2.imgbox.com/e0/f7/GgipdrsP_o.jpg"
                discount="OSZCZĘDZASZ DO 20,90ZŁ"
                name="3x ⌀30cm Pizza"
                price={104.95}
              />
              <PromoCard
                title="KULINARNE SAFARI"
                description="Odkryj dzikie smaki w jednym zestawie! Zamów teraz 30 nuggetsów i 6 porcji frytek za 99,95 zł i poddaj się kulinarnej przygodzie."
                image="https://images2.imgbox.com/b3/2c/yiousbDS_o.jpg"
                discount="OSZCZĘDZASZ 20ZŁ"
                name="6x Frytki + 30 Nuggetsy"
                price={99.95}
              />
              <PromoCard
                title="SMAKOWE TRÓJKI W BURGEROWEJ EDYCJI"
                description="Zestaw trzech burgerów to idealna propozycja dla miłośników różnorodności smaków. Zamów teraz i rozkoszuj się intensywnością każdego kęsa soczystej wołowiny w połączeniu ze świeżymi warzywami!"
                image="https://images2.imgbox.com/b9/19/XE0SwRSi_o.jpg"
                discount="JEDYNIE ZA 79,95ZŁ"
                name="3x Burger"
                price={79.95}
              />
              <PromoCard
                title="TRZY ZAPIEKANE CUDA DLA PEŁNI SMAKU"
                description="Wypełnij swój żołądek domowym ciepłem! Zestaw trzech zapiekanek to esencja komfortu i aromatu. Wybierz swoje ulubione smaki i podziel się nimi z bliskimi. Każda zapiekanka to unikalna przygoda dla Twojego podniebienia!"
                discount="JEDYNIE ZA 69,95ZŁ"
                image="https://images2.imgbox.com/0f/ad/5VEOR3dT_o.jpg"
                name="3x Zapiekanka"
                price={69.95}
              />
              <PromoCard
                title="TRZY RAZY WIĘCEJ AROMATU"
                description="Dla tych, którzy pragną więcej! Zamów teraz trzy duże pizze za 139,95 zł i zanurz się w trzykrotnie intensywnym doświadczeniu smakowym. To uczta, która sprawi, że każda chwila spędzona z rodziną czy przyjaciółmi stanie się niezapomnianym świętem smaków!"
                image="https://images2.imgbox.com/bd/56/DL3vtMM6_o.jpg"
                discount="OSZCZĘDZASZ DO 21,90ZŁ"
                name="3x ⌀40cm Pizza"
                price={139.95}
              />
              <PromoCard
                title="HAPPY BURGERFEST"
                description="Rozkoszuj się mega smakowym doświadczeniem w jednym zestawie! Odkryj cztery soczyste burgery, dwie porcje chrupiących frytek i dwadzieścia kawałków soczystego kurczaka w naszych aromatycznych nuggetsach, wszystko to teraz dostępne w rewelacyjnej cenie 159,95 zł."
                image="https://images2.imgbox.com/59/e1/a1HGffFf_o.jpg"
                discount="OSZCZĘDZASZ DO 15,85ZŁ"
                name="4x Burger + 2x Frytki + 20x Nuggetsy"
                price={159.95}
              />
              <PromoCard
                title="ZAPIEKANANZA"
                description="Zanurz się w kulinarnym rajdzie! Teraz możesz cieszyć się smakiem 4 pysznych zapiekanek, 2 porcjami chrupiących frytek i aż 20 kawałkami soczystych nuggetsów, wszystko to za jedynie 139,95 zł! To idealna okazja, aby zaspokoić swój głód wraz z rodziną czy przyjaciółmi."
                image="https://images2.imgbox.com/20/18/dLzB6RKd_o.jpg"
                name="4x Zapiekanka + 2x Frytki + 20x Nuggetsy"
                discount="OSZCZĘDZASZ DO 23,85ZŁ"
                price={139.95}
              />
              <PromoCard
                title="FIESTA GRANDE"
                description="Odkryj pełną gamę smaków w naszym wyjątkowym zestawie promocyjnym! Dla prawdziwych smakoszy przygotowaliśmy 2 duże, soczyste pizze, 2 apetyczne burgery, 3 porcje chrupiących frytek i aż 20 kawałków złocistych nuggetsów za 199,95 zł!"
                image="https://images2.imgbox.com/9d/18/64caQs77_o.jpg"
                discount="OSZCZĘDZASZ DO 35,85ZŁ"
                name="2x ⌀40cm Pizza + 2x Burger + 3x Frytki + 20x Nuggetsy"
                price={199.95}
              />
              {/* <PromoCard
                title="GRANDIOSO COMBO"
                description="Ten niezwykły zestaw to prawdziwa uczta dla podniebienia, zawierająca wszystko, co kochasz w jednym miejscu. Dwie duże, soczyste pizze o wybranych przez Ciebie smakach, dwie apetyczne zapiekanki, cztery porcje frytek, dziesięć chrupiących nuggetsów i trzy pyszne sosy."
                image="https://res.cloudinary.com/dqknlkpku/image/upload/v1700584203/piiza_zdfozm.jpg"
                discount="OSZCZĘDZASZ DO 31,85ZŁ"
                name="2x ⌀40cm Pizza + 2x Zapiekanka + 4x Frytki + 10x Nuggetsy + 3x Sos"
                price={199.95}
              />
              <PromoCard
                title="MISTRZOWSKI BANKIET"
                description="Otwórz furtkę do niezwykłego świata smaków dzięki temu zestawowi. W tym zestawie znajdziesz cztery średnie, chrupiące pizze, sześć porcji złocistych frytek i trzy wyjątkowe sosy, które nadadzą każdemu kęsowi niezapomnianego smaku."
                image="https://res.cloudinary.com/dqknlkpku/image/upload/v1700584058/eatingpizza_q5e7th.jpg"
                discount="OSZCZĘDZASZ DO 29,85ZŁ"
                name="4x ⌀30cm Pizza + 6x Frytki + 3x Sos"
                price={209.95}
              /> */}
            </div>
          
          
          </div>
        </div>
      </div>
      <HomepagePopup />
    </div>
  );
};

export default Home;

